<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_GRUPO_USUARIO')"
					class="container-add-new"
					v-bind:class="!subscriptions.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="subscriptions.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + subscriptions.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="mr-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="lg"
			>
				<div class="modal-edicao d-block mb-3 modal-edicao-label">
					<b-row>
						<b-col class="text-modal">
							<label class="label-modal label-date">Data</label>
							<div class="d-flex">
								<datepicker
									class="w-100"
									placeholder="Data Assinatura"
									id="data-assinatura"
									input-class="bg-white"
									v-model="subscriptionSelecionada.subscriptionDate"
									:format="formatter"
									:language="ptBR"
									:bootstrap-styling="true"
									data-cy="Data Assinatura"
									:disabled="loading || isEditing"
								/>
							</div>
						</b-col>
						<b-col class="d-block text-modal">
							<span class="label-modal">Usuário</span>
							<b-form-select
								name="user-subscription"
								class="w-100"
								v-model="subscriptionSelecionada.idUser"
								v-validate="{ required: true }"
								:state="validateState('user-subscription')"
								:options="optionsUsers"
								:disabled="loading || isEditing"
							/>
						</b-col>
					</b-row>

					<b-row>
						<b-col class="text-modal">
							<span class="label-modal">Tipo</span>
							<b-form-select
								name="id-subscription-type"
								class="w-100"
								v-model="subscriptionSelecionada.idSubscriptionType"
								v-validate="{ required: true }"
								:state="validateState('id-subscription-type')"
								:options="subscriptionType"
								:disabled="loading"
							>
							</b-form-select>
						</b-col>
						<b-col class="text-modal">
							<span class="label-modal">Renovação</span>
							<b-form-select
								name="pay-subscription-day"
								class="w-100"
								v-model="subscriptionSelecionada.paymentSubscriptionDay"
								v-validate="{ required: true }"
								:state="validateState('pay-subscription-day')"
								:options="paymentSubscriptionDays"
								:disabled="loading"
							>
							</b-form-select>
						</b-col>
					</b-row>
				</div>

				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
			<b-modal
				ref="modalPayment"
				hide-footer
				title="Pagamentos"
				size="lg"
				no-overflow
				hide-no-focus="true"
			>
				<b-row>
					<b-col class="text-modal">
						<label class="label-modal label-date">Data</label>
						<div class="d-flex">
							<datepicker
								class="w-100"
								placeholder="Data"
								id="pay-date"
								input-class="bg-white"
								v-model="paySubscription.date"
								:format="formatter"
								:language="ptBR"
								:bootstrap-styling="true"
								data-cy="Pay Date"
								:disabled="false"
							/>
						</div>
					</b-col>
					<b-col class="d-block text-modal">
						<span class="label-modal">Valor</span>
						<b-form-input
							name="pay-amount"
							class="w-100 number-class"
							v-model="paySubscription.amountString"
							:disabled="true"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="d-block text-modal">
							<span class="label-modal">Conta</span>
							<b-form-select
								name="pay-account"
								class="w-100"
								v-model="paySubscription.idAccount"
								v-validate="{ required: true }"
								:state="validateState('pay-account')"
								:options="account"
								:disabled="loading"
							/>
					</b-col>
					<b-col class="d-block text-modal">
							<span class="label-modal">Categoria</span>
							<b-form-select
								name="pay-category"
								class="w-100"
								v-model="paySubscription.idCategory"
								v-validate="{ required: true }"
								:state="validateState('pay-category')"
								:options="category"
								:disabled="loading"
							/>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="d-block text-modal mt-3">
						<span class="label-modal">Observações</span>
						<b-form-input
							name="pay-comments"
							class="w-100"
							v-model="paySubscription.comments"
							autocomplete="off"
							placeholder=""
							v-validate="{ required: false }"
							:state="validateState('pay-comments')"
						/>
					</b-col>
				</b-row>

				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalPayment')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="paymentExecute"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="subscriptions.length" class="table-responsive">
			<DataTable
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="gruposUsuarios"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoPayment="possuiPermissao('GER_U_ASSINATURAS')"
				:permissaoEdit="possuiPermissao('GER_U_ASSINATURAS')"
				:permissaoDelete="possuiPermissao('GER_D_ASSINATURAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickPayment="showPayment"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { SubscriptionsService } from "../../services/subscriptions";
	import { UsersService } from "../../services/users";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { CategoryExpensesService } from "../../services/categoryExpenses";
	import { AccountService } from "../../services/account";
	import { formatterSimple } from "@/helpers/common";

	export default {
		components: {
			TemplateBase,
			DataTable,
			Datepicker
		},

		inject: ["parentValidator"],

		data () {
			return {
				ptBR,
				subscriptions: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				users: [],
				optionsUsers: [],
				subscriptionType: [],
				subscriptionSelecionada: {
					idSubscription: "",
					subscriptionDate: "",
					idSubscriptionType: null,
					idUser: null,
					paymentSubscriptionDay: "5"
				},
				paymentSubscriptionDays: [
					{
						value: 5,
						text: "5"
					}, {
						value: 15,
						text: "15"
					}, {
						value: 25,
						text: "25"
					}
				],
				isEditing: false,

				invalidForm: true,
				usersService: new UsersService(),
				subscriptionsService: new SubscriptionsService(),
				accountService: new AccountService(),
				categoryService: new CategoryExpensesService(),

				errMsg: "Nenhuma assinatura de usuário cadastrada!",
				colunas: [
					"Usuário",
					"Tipo Assinatura",
					"Data Assinatura",
					"Dia Renovação",
					"Vencimento"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: -1
				},
				paySubscription: {
					date: new Date(),
					amountString: "0,00",
					comments: "",
					idAccount: null,
					idCategory: null,
					amount: 0,
					idSubscription: null,
					nextPayDate: null,
					idSubscriptionType: null,
					subscriptionDate: null,
					paymentSubscriptionDay: null,
					idUser: null
				},
				account: [],
				category: [],
				config: {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			await Promise.all([
				this.findUsers(),
				this.findSubscriptions(),
				this.findSubscriptionType(),
				this.findCategory(),
				this.findAccount()
			]);
		},

		methods: {
			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findSubscriptions();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findSubscriptions();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findSubscriptions();
			},

			async findSubscriptions () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.subscriptionsService.listSubscriptions({offset, limit, sortTable}) || {};
				this.subscriptions = result.rows || [];
				this.linhas = result.rows.reduce((acc, subs) => [
					...acc,
					{
						idSubscription: subs.idSubscription,
						idUser: subs.idUser,
						cols: [
							subs.name,
							subs.subscriptionType,
							formatterSimple(subs.subscriptionDate),
							subs.paymentSubscriptionDay,
							formatterSimple(subs.nextPayDate)
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async findUsers () {
				this.users = await this.usersService.getAllUsersSubscriptionRequired() || [];
			},

			async findSubscriptionType () {
				const resp = await this.subscriptionsService.getSubscriptionType() || [];
				this.subscriptionType = resp.map((st) => ({
					value: st.idSubscriptionType,
					text: st.description
				}));
			},

			async findAccount () {
				const result = await this.accountService.listAccount({offset: 0, limit: null}) || {};
				this.account = result.rows?.reduce((acc, item) => {
					if (item.accountClass.automaticPay) {
						acc.push({
							text: item.description,
							value: item.idAccount
						});
					}

					return acc;
				}, []);
			},

			async findCategory () {
				const result = await this.categoryService.listCategoryExpenses({offset: 0, limit: null}) || {};
				this.category = result.rows?.reduce((acc, item) => {
					if (item.income)
						acc.push({ text: item.description, value: item.idCategoryExpense });

					return acc;
				}, []);
			},

			mostrarEdicao (item_) {
				let item = {};
				if (item_.idSubscription)
					[ item ] = this.subscriptions.filter(subs => subs.idSubscription === item_.idSubscription);

				this.isEditing = false;
				if (!item.idSubscription) {
					this.optionsUsers = this.users.reduce((acc, user) => {
						if (!user.subscriptionRequired) {
							acc.push({
								value: user.idUser,
								text: user.name
							});
						}

						return acc;
					}, []);
					this.titleModalEdicao = "Cadastrar Nova Assinatura de Usuário";
				}
				else {
					this.optionsUsers = this.users.map((user) => ({
						value: user.idUser,
						text: user.name
					}));
					this.isEditing = true;
					this.titleModalEdicao = "Editar Assinatura de Usuário";
				}

				this.invalidForm = true;
				this.subscriptionSelecionada.idSubscription = item.idSubscription || "";
				this.subscriptionSelecionada.name = item.name || "";
				this.subscriptionSelecionada.subscriptionDate = item.subscriptionDate || new Date();
				this.subscriptionSelecionada.idSubscriptionType = item.idSubscriptionType;
				this.subscriptionSelecionada.idUser = item.idUser;
				this.subscriptionSelecionada.paymentSubscriptionDay = item.paymentSubscriptionDay;

				this.$refs.modalEdicao.show();
			},

			showPayment (item_) {
				let item = {};
				if (item_.idSubscription)
					[ item ] = this.subscriptions.filter(subs => subs.idSubscription === item_.idSubscription);

				this.limparModal();
				this.paySubscription.date = new Date();
				this.paySubscription.amountString = item.amountSubscription.replace(".", ",");
				this.paySubscription.amount = parseFloat(item.amountSubscription);
				this.paySubscription.comments = "";
				this.paySubscription.idAccount = null;
				this.paySubscription.idCategory = null;
				this.paySubscription.idSubscription = item.idSubscription;
				this.paySubscription.nextPayDate = item.nextPayDate;
				this.paySubscription.idSubscriptionType = item.idSubscriptionType;
				this.paySubscription.subscriptionDate = item.subscriptionDate;
				this.paySubscription.paymentSubscriptionDay = item.paymentSubscriptionDay;
				this.paySubscription.idUser = item.idUser;

				this.invalidForm = true;
				this.$refs.modalPayment.show();
			},

			async paymentExecute () {
				const config = this.config;
				const baixaConfirmation = (await this.$swal({
					title: "Baixa",
					text: "Tem certeza que deseja executar a baixa?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!baixaConfirmation)
					return;

				const payload = {
					date: this.paySubscription.date,
					amount: this.paySubscription.amount,
					comments: this.paySubscription.comments,
					idIncomeAccount: this.paySubscription.idAccount,
					idCategoryIncome: this.paySubscription.idCategory,
					transactionType: "INCOME",
					subscriptionData: {
						idSubscription: this.paySubscription.idSubscription,
						nextPayDate: this.paySubscription.nextPayDate,
						idSubscriptionType: this.paySubscription.idSubscriptionType,
						subscriptionDate: this.paySubscription.subscriptionDate,
						paymentSubscriptionDay: this.paySubscription.paymentSubscriptionDay,
						idUser: this.paySubscription.idUser
					}
				};

				this.$snotify.async("Aguarde...", "Executando baixas", async () => {
					try {
						await this.subscriptionsService.executePay({payload});

						this.findSubscriptions();
						this.fecharModal("modalPayment");
						this.limparModal();

						return {
							title: "Sucesso!",
							body: "Assinatura renovada com sucesso!",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar renovar assinatura!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					idSubscription: this.subscriptionSelecionada.idSubscription,
					idUser: this.subscriptionSelecionada.idUser,
					subscriptionDate: this.subscriptionSelecionada.subscriptionDate,
					idSubscriptionType: this.subscriptionSelecionada.idSubscriptionType,
					paymentSubscriptionDay: this.subscriptionSelecionada.paymentSubscriptionDay
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.subscriptionsService.upsertSubscription({payload});

						this.findUsers();
						this.findSubscriptions();
						this.fecharModal("modalEdicao");

						return {
							title: "Sucesso!",
							body: "Assinatura de usuário salva com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar assinatura de usuário!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			limparModal () {
				this.invalidForm = true;
				this.subscriptionSelecionada.idSubscription = null;
				this.subscriptionSelecionada.name = "";
				this.subscriptionSelecionada.subscriptionDate = new Date();
				this.subscriptionSelecionada.idSubscriptionType = null;
				this.subscriptionSelecionada.idUser = null;
				this.subscriptionSelecionada.paymentSubscriptionDay = 5;

				this.paySubscription.date = new Date();
				this.paySubscription.amount = "0;00";
				this.paySubscription.comments = "";
				this.paySubscription.idAccount = null;
				this.paySubscription.idCategory = null;
			},

			async confirmaApagar (item_) {
				const [ item ] = this.groupsUsers.filter((gu) => gu.idGroupUser === item_.idGroupUser);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o grupo de usuário?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idGroupUser);
			},

			async apagar (idGroupUser) {
				const config = this.config;
				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.groupsUsersService.deleteGroupUser({idGroupUser});
						this.findSubscriptions();

						return {
							title: "Sucesso!",
							body: "Assinatura de usuário deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar assinatura de usuário!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			}
		}
	};
</script>

<style scoped>
	.text-modal {
		font-family: 'Roboto Condensed Regular';
		font-size: 16px;
		letter-spacing: -0.05em;
	}

	.label-modal {
		color: #696969 !important;
	}

	.label-date {
		margin-bottom: -3px;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
		letter-spacing: -0.05em;

	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}

	.number-class {
		text-align: right;
	}

	@import '../../assets/css/custom-style.css'
</style>
